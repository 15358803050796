<template>
  <div>
    <div class="bannerCommon swiper_warp">
      <div class="swiper-container banner-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index" @click="$bannerClick(item)">
            <!--<img class="bannerCommon_img" :src="'http://'+item.image" v-if="index == 0 || index == (item.length - 1)">
            <img class="bannerCommon_img" v-lazy="'http://'+item.image" alt="" srcset="" v-else>-->
						<img class="bannerCommon_img" :src="'http://'+item.image">
          </div>
        </div>
        <div class="swiper-pagination banner-pagination"></div>
      </div>
    </div>
    <div class="product wBody">
      <div class="title-module wow slideInLeft">
        <span class="cn">产品中心</span>
        <span class="en">PRODUCT CENTER</span>
        <div class="line"></div>
      </div>
      <div class="banner-my">
        <div
          class="banner-list mySwiper2 mySwiper swiper-initialized swiper-vertical swiper-pointer-events swiper-backface-hidden">
          <div class="swiper-wrapper" id="swiper-wrapper-2f15e2eb761f213a" aria-live="polite">
            <div class="swiper-slide" role="group" :aria-label="`${index + 1} / ${product.length}`"
              v-for="(item, index) in product" :key="index" @mouseenter="hoverNow(index)">
              <div class="item-banner" :class="{ 'bannerNow': currentIndex == index }">
                <div class="title">
                  <span class="nameT">{{ item.post_title }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-top" tabindex="0" role="button" aria-label="Previous slide"
            aria-controls="swiper-wrapper-1011f0bdb210502456" aria-disabled="false">
            <img src="@/assets/images/index/icon-10.png" alt="" srcset="">
          </div>
          <div class="btn-bottom" tabindex="0" role="button" aria-label="Next slide"
            aria-controls="swiper-wrapper-1011f0bdb210502456" aria-disabled="false">
            <img src="@/assets/images/index/icon-10.png" alt="" srcset="">
          </div>
        </div>
        <div class="box">
          <img class="mainImg" :src="$store.state.baseUrl+'/upload/'+item.more.photos[0].url" alt="" srcset="" v-for="(item, index) in product"
            :key="index" @click="$router.push('/productDetail?did=' + item.id + '&isHome=true')"
            v-show="currentIndex == index">
        </div>
      </div>
    </div>
    <div class="about">
      <div class="conBg">
        <div class="conBox wBody">
          <div class="imgBox wow bounceIn">
            <img src="@/assets/images/index/indexAboutImg.png" alt="" srcset="">
          </div>
          <div class="con wow slideInRight">
            <div class="title">关于融润家居</div>
            <div class="desc">
              <p> 融润家居坐落于全球定制之都——广州。是一家专注于全屋家具生产、定制设计的高新企业，拥有23年国际高端奢侈品家具定制历史。长期为国内外知名奢侈品家具行业客户提供专业化的高品质服务。</p>
              <p>
                融润拥有30多名高级工程师和设计师，及100多名专业技术人员。自有20000平米现代化生产基地，八大独立生产车间，瑞士进口生产线，生产标准与国际接轨。拥有ISO三大体系认证，50多项技术和外观专利。同时，拥有两大高级信用认证，三大环保认证，10星售后服务认证等，是为数不多的高新技术企业。
              </p>
              <p>融润家居源于创始人对美的追求及对品质的执念，坚持“服务为钢，品质为王”，专注于为客户提供设计、智造、一体化整体输出服务，为用户提供高品质的产品，以满足不同的定制要求。</p>
              <p>经过20多年国内外奢侈品品牌严苛检验，让融润沉淀和历练了精湛的制作工艺、前沿时尚与艺术的设计理念、全球化的供应链及全球化的交付能力。目前，已服务了全球超过70多个国家和地区。</p>
            </div>
          </div>
        </div>
        <div class="bottomBox wBody">
          <div class="item wow rollIn" @click="goActive1(1)">
            <img src="@/assets/images/index/indexAboutIcon1.png" alt="" srcset="">
            <span>瑞士进口生产线</span>
          </div>
          <div class="item wow rollIn" @click="goActive1(2)">
            <img src="@/assets/images/index/indexAboutIcon2.png" alt="" srcset="">
            <span>20000m²生产基地</span>
          </div>
          <div class="item wow rollIn" @click="goActive1(3)">
            <img src="@/assets/images/index/indexAboutIcon3.png" alt="" srcset="">
            <span>30+资深设计师</span>
          </div>
          <div class="item wow rollIn" @click="goActive1(4)">
            <img src="@/assets/images/index/1.png" alt="" srcset="">
            <span>50+多项专利</span>
          </div>
        </div>
      </div>
    </div>
    <div class="company wBody">
      <div class="title-module wow slideInLeft">
        <span class="cn">公司动态</span>
        <span class="en">CONPANY NEWS</span>
        <div class="line"></div>
      </div>
      <div class="news">
        <div class="news-con" @click="$router.push('/ronrunNews/newsCenterDetail?did=' + companyNews[newsIndex].id)">
          <div class="mainImg" v-if="companyNews[newsIndex]">
            <img :src="$store.state.baseUrl+'/upload/'+companyNews[newsIndex].more.thumbnail" alt="" srcset="">
            <div class="con">
              <div class="title">

                <span class="qoverDot1" style="font-size: 18px;">{{ companyNews[newsIndex].post_title }}</span>
              </div>
              <p class="desc qoverDot2">{{ companyNews[newsIndex].post_excerpt&&$setText(companyNews[newsIndex].post_excerpt) }}
              </p>
              <div class="time">{{ companyNews[newsIndex].published_time }}</div>
            </div>
          </div>
        </div>
        <div class="news-list">
          <div class="item" v-for="(item, index) in companyNews" :key="index" :class="{ 'itemClick': index == newsIndex }"
            @mouseenter="changeNews(index)" @click="$router.push('/ronrunNews/newsCenterDetail?did=' + item.id)">
            <div class="time">
              <span class="day">{{ item.published_time&&item.published_time.substr(8, 2) }}</span>
              <span class="year">{{ item.published_time&&item.published_time.substr(0, 7) }}</span>
            </div>
            <div class="con">
              <div class="title">
                <span class="qoverDot1">{{ item.post_title }}</span>
              </div>
              <p class="desc qoverDot2">{{ item.post_excerpt&&$setText(item.post_excerpt) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="adver">
      <div class="con wBody">
        <div class="item wow lightSpeedIn" @click="goActive1(5)">
          <img class="one" src="@/assets/images/index/indexAdver1.png" alt="" srcset="">
          <img class="two" src="@/assets/images/index/indexAdver1Check.png" alt="" srcset="">
          <span>10星服务</span>
        </div>
        <div class="item wow lightSpeedIn" @click="goActive1(6)">
          <img class="one" src="@/assets/images/index/indexAdver2.png" alt="" srcset="">
          <img class="two" src="@/assets/images/index/indexAdver2Check.png" alt="" srcset="">
          <span>预约尺寸</span>
        </div>
        <div class="item wow lightSpeedIn" @click="goActive1(7)">
          <img class="one" src="@/assets/images/index/indexAdver3.png" alt="" srcset="">
          <img class="two" src="@/assets/images/index/indexAdver3Check.png" alt="" srcset="">
          <span>问题反馈</span>
        </div>
        <div class="item wow lightSpeedIn" @click="goActive1(8)">
          <img class="one" src="@/assets/images/index/indexAdver4.png" alt="" srcset="">
          <img class="two" src="@/assets/images/index/indexAdver4Check.png" alt="" srcset="">
          <span>联系我们</span>
        </div>
      </div>
    </div>
    <div class="serve wBody">
      <div class="title-module wow slideInLeft">
        <span class="cn">服务标准</span>
        <span class="en">SERVICE STANDARD</span>
        <div class="line"></div>
      </div>
      <div class="serve-list wow slideInLeft">
        <div class="item" @click="goActive1(9)">
          <img src="@/assets/images/index/indexService1.jpg" alt="" srcset="">
          <div class="con">
            <span class="title">微笑服务20多年</span>
            <div class="line"></div>
            <span class="desc">见到融润，见到微笑</span>
          </div>
        </div>
        <div class="item" @click="goActive1(10)">
          <img src="@/assets/images/index/indexService2.jpg" alt="" srcset="">
          <div class="con">
            <span class="title">4大承诺</span>
            <div class="line"></div>
            <span class="desc">质量/环保/售后//服务</span>
          </div>
        </div>
        <div class="item" @click="goActive1(11)">
          <img src="@/assets/images/index/indexService3.jpg" alt="" srcset="">
          <div class="con">
            <span class="title">防伪查询</span>
            <div class="line"></div>
            <span class="desc">防伪查询</span>
          </div>
        </div>
        <div class="item" @click="goActive1(7)">
          <img src="@/assets/images/index/indexService4.jpg" alt="" srcset="">
          <div class="con">
            <span class="title">专属客服</span>
            <div class="line"></div>
            <span class="desc">快速反馈 用心服务</span>
          </div>
        </div>
      </div>
    </div>


    <div style="height:100px;"></div>
  </div>

</template>

<script>

import { getCompanyNew, getHomeProduct, getHomeSlide } from "@/api/home";
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  name: 'HomeView',
  metaInfo: {
    title: '融润家居',// 设置页面title
    meta: [{
      name: 'Keywords',
      content: '融润家居'
    },{
      name: 'Description',
      content: '融润家居创立于2000年，是中国较早进入不锈钢家居领域的优秀企业之一。集设计、研发、生产、销售不锈钢家居产品于一体的国家高新技术企业，一直以来，融润都在为家人的舞台定制美的、极致的、专属的生活空间和不锈钢家居产品，不锈钢家居定制加盟就选融润！'
    }]
  },
  data() {
    return {
      mySwiper2: null,  //商品轮播
      product: [],  //推荐商品
      currentIndex: 0,  //hover下标
      companyNews: [], //公司动态列表
      newsIndex: 0, //新闻切换
      bannerList: [],
      mySwiperBanner: null,  //顶部轮播图
    };
  },
  mounted() {
    this.loadCompanyNew()
    this.loadProduct()
    // 首页轮播图
    this.loadBanner();

    setTimeout(() => {
      this.initSwiper2();
    }, 100)
    // this.$nextTick(() => {
    //   new this.$wow.WOW().init();
    // });

  },
  methods: {
    goActive1(active1Id) {
      if (active1Id == 1) {
        this.$router.push('/joinInvestment/brandStrength?active1Id=' + active1Id)
      } else if (active1Id == 2) {
        this.$router.push('/AboutUs/brandIntroduction?active1Id=' + active1Id)
      } else if (active1Id == 3) {
        this.$router.push('/AboutUs/brandCulture?active1Id=' + active1Id)
      } else if (active1Id == 4) {
        this.$router.push('/AboutUs/brandIntroduction?active1Id=' + active1Id)
      } else if (active1Id == 5) {
        this.$router.push('/joinInvestment/brandStrength?active1Id=' + active1Id)
      } else if (active1Id == 6) {
        this.$router.push('/serviceSupport/reserveGauge?active1Id=' + active1Id)
      } else if (active1Id == 7) {
        this.$router.push('/serviceSupport/problem?active1Id=' + active1Id)
      } else if (active1Id == 8) {
        this.$router.push('/AboutUs/contractUs?active1Id=' + active1Id)
      } else if (active1Id == 9) {
        this.$router.push('/serviceSupport/ronrunService?active1Id=' + active1Id)
      } else if (active1Id == 10) {
        this.$router.push('/serviceSupport/ronrunService?active1Id=' + active1Id)
      } else if (active1Id == 11) {
        this.$router.push('/serviceSupport/genuineProduct?active1Id=' + active1Id)
      } else {

      }
    },
    // 加载轮播图
    loadBanner() {
      let that = this;
      getHomeSlide({
        type: 1
      }).then((res) => {
        if (res.code  === 1) {
          let list = res.data;
          this.bannerList = list;
          setTimeout(() => {
            this.initSwiperBanner();
          }, 100)
        }
      });
    },
    changeNews(index) {  //切换公司动态
      this.newsIndex = index
    },
    // 加载公司动态
    loadCompanyNew() {
      getHomeProduct({
				type:1
			}).then((res) => {
        if (res.code  === 1) {
          let list = res.data;
          this.companyNews = list;
        }
      });
    },
    hoverNow(idx) {
      this.currentIndex = idx
    },
    // 加载推荐商品
    loadProduct() {
      let that = this;
      getHomeProduct({
				type:2
			}).then((res) => {
        if (res.code  === 1) {
          let list = res.data;
          this.product = list;
          setTimeout(() => {
            this.initSwiper2();
          }, 100)
        }
      });
    },
    initSwiperBanner() {
      this.mySwiperBanner = new Swiper('.banner-container', {
        autoplay: true,
        height: 554,
        loop: true, // 循环模式选项
        loopAdditionalSlides: 4,
        // 如果需要分页器
        pagination: {
          el: '.banner-pagination',
          clickable: true, // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.arrow-right',
          prevEl: '.arrow-left',
        },
        on: {
          resize: () => {
            setTimeout(() => {
              this.mySwiperBanner.update()
            }, 100)
          },
        },
      });

    },
    initSwiper() {
      this.mySwiper = new Swiper('.banner-container', {
        //direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: true,
        height: 554,
        // width: window.innerWidth,
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: '.banner-pagination',
          clickable: true, // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.arrow-right',
          prevEl: '.arrow-left',
        },
        on: {
          resize: () => {
            setTimeout(() => {
              this.mySwiper.update()
            }, 100)
          },
        },
      });

    },
    initSwiper2() {
      this.mySwiper2 = new Swiper(".mySwiper2", {
        direction: "vertical",
        slidesPerView: 7,
        spaceBetween: 4,
        pagination: {
          el: ".swiper-pagination2",
          type: "fraction",
        },
        navigation: {
          nextEl: ".btn-bottom",
          prevEl: ".btn-top",
        },
        on: {
          resize: () => {
            setTimeout(() => {
              this.mySwiper2.update()
            }, 100)
          },
        },
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.bannerCommon {
  height: 775px;
}

.bannerCommon_img {
  width: 100%;
  height: 775px;
  object-fit: cover;
}

.product {
  margin-top: 60px;

  .banner-my {
    position: relative;
    margin-top: 54px;

    .box {
      position: absolute;
      top: 0;
      right: 0;
      width: 936px;
      height: 478px;
      z-index: 3;
      cursor: pointer;



      .mainImg {
        width: 936px;
        height: 478px;
        object-fit: cover;
        cursor: pointer;
        transition: all .5s;
      }
    }

    .banner-list {
      position: relative;
      flex: 1;
      height: 478px;
      overflow: hidden;
      border: 1px solid #d4d4d4;
      border-right-width: 0;

      .btn-top,
      .btn-bottom {
        overflow: hidden;
        z-index: 111;
        position: absolute;
        width: 224px;
        height: 40px;
        border: 0;
        background: rgba(0, 0, 0, 0.15);
        cursor: pointer;
        transition: all .5s ease;
        text-align: center;

        img {
          width: 15px;
        }
      }

      .btn-top {
        top: 0;
        left: 0;
        transform: translate(0, -100%);
      }

      .btn-bottom {
        bottom: 0;
        left: 0;
        transform: translate(0, 100%);
      }

      &:hover .btn-top {
        transform: translate(0, 0);

        img {
          transform: translate(-50%, -50%) rotate(-90deg);
          animation: gotTop 2s linear infinite;
        }
      }

      &:hover .btn-bottom {
        transform: translate(0, 0);

        img {
          transform: translate(-50%, -50%) rotate(90deg);
          animation: gotBottom 2s linear infinite;
        }
      }

      @keyframes gotTop {
        0% {
          transform: translate(-50%, 25px) rotate(-90deg);
        }

        100% {
          transform: translate(-50%, -25px) rotate(-90deg);
        }
      }

      @keyframes gotBottom {
        0% {
          transform: translate(-50%, -25px) rotate(90deg);
        }

        100% {
          transform: translate(-50%, 25px) rotate(90deg);
        }
      }

      .item-banner {
        width: 224px;
        height: 77px;
        background: #fff;
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid #d4d4d4;

        .title {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .nameT {
            position: relative;
            font-size: 14px;

            &::before {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -10px;
              width: 24px;
              height: 2px;
              background: #b02930;
            }

          }
        }

        &:hover {
          background: #333333;
          color: #fff;
        }

        &:hover .nameT {
          &::before {
            background: #333333;
          }



        }


      }

      .bannerNow {
        background: #333333;
        color: #fff;

        .title {
          .nameT {
            &::before {
              background: #333333;
            }

          }
        }

      }

    }

  }
}


.about {
  position: relative;
  margin-top: 133px;
  height: 905px;
  background: url(@/assets/images/index/indexAboutBg.png) no-repeat;
  background-size: 100% 100%;
  min-height: 905px;

  .conBg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(55, 59, 63, .94);

    .conBox {
      position: relative;
      top: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .imgBox {
        width: 490px;
        height: 656px;

        &::before {
          content: "";
          position: absolute;
          left: 30px;
          top: -35px;
          width: 490px;
          height: 656px;
          border: 1px dashed #fff;
        }

        img {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;
        }
      }

      .con {
        flex: 1;
        margin-left: 54px;
        color: #fff;

        .title {
          position: relative;
          padding-bottom: 25px;
          font-size: 25px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 22px;
            height: 1.5px;
            background: #b02930;
          }
        }

        .desc {
          margin-top: 4px;
          font-size: 14px;

          p {
            margin-top: 20px;
            line-height: 25px;
            text-indent: 2em;
          }
        }
      }
    }

    .bottomBox {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 214px;
      border-radius: 10px;
      z-index: 3;
      background: #fff;
      box-shadow: 0px 5px 10px 0px #ccc;

      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        transition: all .5s;

        img {
          width: 70px;
          margin-bottom: 30px;
        }

        span {
          position: relative;
          font-size: 18px;

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: -12px;
            transform: translateX(-50%);
            width: 22px;
            height: 1.5px;
            background: #b02930;
          }
        }

        &:hover {

          background: #b02930;
          color: #fff;

          img {
            filter: invert(98%) sepia(100%) saturate(0%) hue-rotate(237deg) brightness(104%) contrast(106%);
          }

          span {
            &::before {
              background: #fff;
            }
          }

        }

        &:nth-child(1):hover {
          border-radius: 10px 0 0 10px;

        }

        &:nth-child(2):hover {
          border-radius: 0;

        }

        &:nth-child(3):hover {
          border-radius: 0;

        }

        &:nth-child(4):hover {
          border-radius: 0 10px 10px 0;

        }


        // &:first-child {
        //   border-radius: 10px 0 0 10px;
        //   background: #b02930;
        //   color: #fff;

        //   span {
        //     &::before {
        //       background: #fff;
        //     }
        //   }
        // }
      }

    }

  }
}

.company {
  margin-top: 194px;

  .news {
    display: flex;
    justify-content: space-between;
    margin-top: 39px;

    .news-con {
      width: 765px;
      height: 664px;
      background: #f0f0f0;
      border-radius: 2px;

      .mainImg {
        position: relative;

        height: 474px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }

        .con {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
          width: 673px;
          padding: 49px 44px 30px 44px;
          background: #fff;
          box-shadow: 0px 5px 10px 0px #ccc;

          .title {
            padding-bottom: 30px;
            border-bottom: 1px solid #eee;
          }

          .desc {
            margin-top: 25px;
            line-height: 25px;
            color: #8c8c8c;
            font-size: 14px;
          }

          .time {
            margin-top: 50px;
            color: #8c8c8c;
            font-size: 14px;
          }
        }
      }

    }

    .news-list {
      flex: 1;
      margin-left: 14px;
      height: 664px;
      overflow: auto;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 208px;
        background: #f0f0f0;
        border-radius: 2px;
        margin-bottom: 20px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        .time {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 70px;
          height: 120px;
          border-radius: 2px 0 0 2px;
          background: #333333;
          color: #fff;
          padding: 36px 0 20px 0;

          .day {
            font-size: 36px;
          }

          .year {
            font-size: 14px;
          }
        }

        .con {
          position: relative;
          width: 280px;
          height: 168px;
          background: #fff;
          border-radius: 2px;
          box-shadow: 3px 5px 10px 0px #ccc;
          padding: 26px;

          &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            width: 280px;
            height: 2px;
            background: #fff;
          }

          .title {
            font-size: 18px;
            padding-bottom: 14px;
            border-bottom: 1px dashed #8c8c8c;
          }

          .desc {
            margin-top: 20px;
            color: #8c8c8c;
            font-size: 14px;
            line-height: 25px;
          }
        }
      }

      .itemClick {
        .time {
          background: #b02930;
        }

        .con {
          &::after {
            background: #b02930;
          }
        }
      }

    }
  }
}

.adver {
  background: url(@/assets/images/index/indexAdverBg.jpg) no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 5px 10px 0px #ccc;
  height: 248px;
  margin-top: 63px;

  .con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: #fff;
      cursor: pointer;

      img {
        width: 70px;
        margin-bottom: 30px;
        transition: all .5s;
      }

      .one {
        display: block;
      }

      .two {
        display: none;
      }

      span {
        position: relative;
        font-size: 18px;
        transition: all .5s;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: -12px;
          transform: translateX(-50%);
          width: 22px;
          height: 1.5px;
          background: #b02930;
        }
      }

      &:hover {

        color: #b02930;

        .one {
          display: none;
        }

        .two {
          display: block;
        }

      }
    }
  }
}

.serve {
  margin-top: 76px;

  .serve-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 43px;

    .item {
      width: 279px;
      cursor: pointer;

      img {
        width: 100%;
        height: 281px;
        border-radius: 2px 2px 0 0;
      }

      .con {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #f0f0f0;
        margin-top: -5px;
        padding: 28px 0 20px 0;
        transition: all .5s;


        .title {
          font-size: 18px;
        }

        .line {
          width: 24px;
          height: 1.5px;
          background: #b02930;
          margin: 13px 0;
        }

        .desc {
          font-size: 14px;
        }
      }

      &:hover {
        .con {
          background: #b02930;
          color: #fff;

          .line {
            background: #fff;
          }
        }
      }

    }
  }
}
</style>
