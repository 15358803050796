<template>
    <div>
        <!-- <div class="tab">
            <div class="tab-list wBody">
                <div class="title" @click="$router.push('/serviceSupport')">
                    服务支持
                </div>
                <div class="list">
                    <ul>
                        <li v-for="(item, index) in tabList" :key="index" :class="{ 'isCheck': tabIndex == index }"
                            @click="tabClick(index, item)">
                            <router-link :to="item.url">
                                <span>{{ item.name }}</span>
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
        </div> -->
        <div class="otherLink">
            <router-view />
        </div>

    </div>
</template>
  
<script>

export default {
    name: 'serviceSupport',
    data() {
        return {
            tabList: [{ name: '融润服务', url: '/serviceSupport/ronrunService' }, { name: '正品查询', url: '/serviceSupport/genuineProduct' }, { name: '常见问题', url: '/serviceSupport/problem' }, { name: '预约量尺', url: '/serviceSupport/reserveGauge' }],
            tabIndex: 0,   //选项卡下标
        };
    },
    mounted() {

    },
    methods: {
        tabClick(index, item) {
            this.tabIndex = index
        },
    }
}
</script>
<style lang="scss" scoped>
.tab {
    height: 78px;
    background: #333;
    color: #fff;
    font-size: 15px;

    .tab-list {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .list {
            height: 100%;

            ul {
                height: 100%;
                display: flex;
                align-items: center;

                li {
                    height: 100%;
                    width: 100px;
                    display: flex;
                    align-items: center;

                    a {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                }

                .isCheck {
                    color: #8e2432;
                }
            }
        }
    }
}
</style>
  