import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//这里的Vue要做修改，等会就知道了
Vue.use(ElementUI);

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.prototype.$bannerClick = function (item) {
  if (item.isOpen == '1') {
    window.open(item.openParam, '_blank');
  }
}
Vue.prototype.$fixUrl = function (str,type=1) {
  if (type==1) {
    return 'http://'+str;
  }else if(type==2){
		return this.$store.state.baseUrl+'/upload/'+str;
	}
}


Vue.prototype.$phone = function (phone) {
  if (phone) {
    return phone.substr(0, 3) + ' ' + phone.substr(3, 4) + ' ' + phone.substr(7, 4);
  }
}
Vue.prototype.$richImg = function (richtext) {
  if (!richtext) {
    return;
  }
  const regex = new RegExp('<img', 'gi');
  return richtext.replace(/(\<img|\<p|\<span)/gi, function ($0, $1) {
    return {
      "<img": '<img style="max-width:100%;height:auto;"',
      "<p": '<p',
      "<span": '<span style="font-size:14px!important;"',
      "<article": "<div",
      "</article": "</div",
      "<header": "<div",
      "</header": "</div"
    }[$1];
  });
}

Vue.prototype.$setText = function (html) {
  return html.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
    .replace(/<br\/?>/gi, '\n')
    .replace(/<[^>/]+>/g, '')
    .replace(/(\n)?<\/([^>]+)>/g, '')
    .replace(/\u00a0/g, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/<img[^>]+src\\s*=\\s*['\"]([^'\"]+)['\"][^>]*>/g, '')
    .replace(/<\/?(img|table)[^>]*>/g, "")   // 去除图片和表格
    .replace(/<\/?(a)[^>]*>/g, "")			//  去除a标签
}

import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow


//引入vue懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// import loading from '@/assets/loadImg/load.gif'
// //注册图片懒加载  
// Vue.use(VueLazyload, {
//   // preLoad: 1.3,
//   error: '@/assets/loadImg/loadfail.png',//图片错误的替换图片路径(可以使用变量存储)
//   loading: loading,//正在加载的图片路径(可以使用变量存储)
//   // attempt: 1
// })


// Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
