
import HomeView from '../views/PC/HomeView.vue'
import AboutUs from '../views/PC/aboutUs/aboutUs.vue'
import joinInvestment from '../views/PC/joinInvestment/joinInvestment.vue'
import serviceSupport from '../views/PC/serviceSupport/serviceSupport.vue'
import ronrunNews from '../views/PC/ronrunNews/ronrunNews.vue'

const routes = [
  {
    path: '/',
    redirect: '/HomeView'
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    redirect: '/AboutUs/brandIntroduction',
    component: AboutUs,
    children: [
      {
        path: 'brandIntroduction',
        component: () => import('@/views/PC/aboutUs/brandIntroduction')
      },
      {
        path: 'brandCulture',
        component: () => import('@/views/PC/aboutUs/brandCulture')
      },
      {
        path: 'talentRecruitment',
        component: () => import('@/views/PC/aboutUs/talentRecruitment')
      },
      {
        path: 'contractUs',
        component: () => import('@/views/PC/aboutUs/contractUs')
      }
    ]
  },
  {
    path: '/joinInvestment',
    name: 'joinInvestment',
    redirect: '/joinInvestment/brandStrength',
    component: joinInvestment,
    children: [
      {
        path: 'brandStrength',
        component: () => import('@/views/PC/joinInvestment/brandStrength')
      },
      {
        path: 'joiningProcess',
        component: () => import('@/views/PC/joinInvestment/joiningProcess')
      }
    ]
  },
  {
    path: '/product', 
    name: 'product',
    component: () => import('@/views/PC/product/product')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('@/views/PC/product/productDetail')
  },
  {
    path: '/serviceSupport',
    name: 'serviceSupport',
    redirect: '/serviceSupport/ronrunService',
    component: serviceSupport,
    children: [
      {
        path: 'ronrunService',
        component: () => import('@/views/PC/serviceSupport/ronrunService')
      },
      {
        path: 'genuineProduct',
        component: () => import('@/views/PC/serviceSupport/genuineProduct')
      },
      {
        path: 'problem',
        component: () => import('@/views/PC/serviceSupport/problem')
      },
      {
        path: 'reserveGauge',
        component: () => import('@/views/PC/serviceSupport/reserveGauge')
      }
    ]
  },
  {
    path: '/ronrunNews',
    name: 'ronrunNews',
    redirect: '/ronrunNews/newsCenter',
    component: ronrunNews,
    children: [
      {
        path: 'newsCenter',
        component: () => import('@/views/PC/ronrunNews/newsCenter')
      },
      {
        path: 'newsCenterDetail',
        component: () => import('@/views/PC/ronrunNews/newsCenterDetail')
      },
      {
        path: 'brandVideo',
        component: () => import('@/views/PC/ronrunNews/brandVideo')
      },
      {
        path: 'activity',
        component: () => import('@/views/PC/ronrunNews/activity')
      },
      {
        path: 'activityDetail',
        component: () => import('@/views/PC/ronrunNews/activityDetail')
      },
      {
        path: 'ecology',
        component: () => import('@/views/PC/ronrunNews/ecology')
      },
    ]
  },
  {
    path: '/engineering',
    name: 'engineering',
    component: () => import('@/views/PC/engineering/engineering')
  },
 
]

export default routes
