<template>
    <div id="footer">
        <div class="footer">
            <div class="wBody">
                <div class="logo-menu">
                    <div class="left">
                        <div class="logo">
                            <img src="@/assets/logo2.png" alt="" srcset="" @click="$router.push('/HomeView')">
                        </div>
                        <div class="phone">
                            <div class="item">
                                <span class="text">全国服务热线</span><br>
                                <span class="phoneNumber">400-000-6438</span>
                            </div>
                            <div class="item">
                                <span class="text">招商财富热线</span><br>
                                <span class="phoneNumber">189 2227 0085</span>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="code-list">
                            <div class="item">
                                <img src="@/assets/images/common/xhs.png" alt="" srcset="">
                                <div class="itemCode" v-if="code[0]">
                                    <img :src="'http://'+code[0].image" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <img src="@/assets/images/common/wx.png" alt="" srcset="">
                                <div class="itemCode" v-if="code[1]">
                                    <img :src="'http://'+code[1].image" alt="">
                                </div>
                            </div>
                            <div class="item">
                                <img src="@/assets/images/common/dy.png" alt="" srcset="">
                                <div class="itemCode" v-if="code[2]">
                                    <img :src="'http://'+code[2].image" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="menu">
                            <dl v-for="(item, index) in menuList" :key="index">

                                <dt @click="$router.push(item.url)">
                                    {{ item.name }}
                                </dt>
                                <dd class="line"></dd>
                                <dd v-for="(cItem, cIndex) in item.childList" :key="cIndex"
                                    @click="childClick(index, cIndex, cItem.url, cItem.id)">{{
                                        cItem.name
                                    }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="link">
                    <span>友情链接：</span>
                    <ul>
                        <li @click="goLink('http://rrwujin.com')">融润钢王</li>
                        <li  @click="goLink('http://www.gzrun.cn')" style="color:#b02930;">融润展柜</li>
                        <li @click="goLink('http://gzifami.com')">爱幸福定制家</li>
                        <li @click="goLink('http://www.gzmeizai.com/')">美在装饰</li>
                        <li @click="goLink('http://www.gzron.com/')">美在工装</li>
                    </ul>
                </div>
            </div>
            <div class="lineBottom"></div>
            <div class="wBody">
                <div class="website">
                    Copyright © 2019 广州融润集成家居限公司. All Rights Reserved.隐私声明<span class="beian"
                        @click="goWin">粤ICP备20058269号-6</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { getRecommend } from "@/api/home";
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Footer',
    data() {
        return {
            menuList: [
                { name: '关于我们', url: '/AboutUs', childList: [{ name: '品牌介绍', url: '/AboutUs/brandIntroduction' }, { name: '品牌历程', url: '/AboutUs/brandIntroduction' }, { name: '品牌荣耀', url: '/AboutUs/brandIntroduction' }, { name: '核心技术', url: '/AboutUs/brandIntroduction' }, { name: '研发中心', url: '/AboutUs/brandIntroduction' }, { name: '品牌文化', url: '/AboutUs/brandCulture' }, { name: '人才招聘', url: '/AboutUs/talentRecruitment' }, { name: '联系我们', url: '/AboutUs/contractUs' }] },
                { name: '招商加盟', url: '/joinInvestment', childList: [{ name: '品牌实力', url: '/joinInvestment/brandStrength' }, { name: '开店支持', url: '/joinInvestment/brandStrength' }, { name: '服务支持', url: '/joinInvestment/brandStrength' }, { name: '加盟流程', url: '/joinInvestment/joiningProcess' }] },
                { name: '产品中心', url: '/product', childList: [] },
                { name: '服务支持', url: '/serviceSupport', childList: [{ name: '融润服务', url: '/serviceSupport/ronrunService' }, { name: '正品查询', url: '/serviceSupport/genuineProduct' }, { name: '常见问题', url: '/serviceSupport/problem' }, { name: '预约量尺', url: '/serviceSupport/reserveGauge' }] },
                { name: '融润资讯', url: '/ronrunNews', childList: [{ name: '新闻中心', url: '/ronrunNews/newsCenter' }, { name: '品牌视频', url: '/ronrunNews/brandVideo' }, { name: '公益活动', url: '/ronrunNews/activity' }, { name: '融润生态', url: '/ronrunNews/ecology' }] },
                { name: '工程合作', url: '/engineering', childList: [{ name: '国际工程', url: '/engineering' }, { name: '国内工程', url: '/engineering' }] }
            ],
            qrCode: [],
            footIndex: 0,
            // product: [],  //推荐商品
        }
    },
    watch: {
        '$route'(to, from) {
            this.activeIndex = to.path;
        }
    },
    computed: mapState(['aboutIndex', 'code']),
    mounted() {
        this.loadProduct()
    },
    methods: {
        ...mapMutations(['setAboutIndex', 'setJoinInvestmentIndex', 'setEngineeringIndex', 'setClassifyVal']),
        childClick(index, index2, url, id) {
            if (index == 0) {
                this.setAboutIndex(index2)
            }
            if (index == 1) {
                this.setJoinInvestmentIndex(index2)
            }
            if (index == 2) {
                document.documentElement.scrollTop = 0;
                // this.setClassifyVal(id)
            }
            this.setJoinInvestmentIndex(index2)
            if (index == 5) {
                this.setEngineeringIndex(index2)
            }
            this.$router.push(`${url}?index=` + index2)
        },
        // 加载推荐商品
        loadProduct() {
            let that = this;
            let list = [{
                url: '/product',
                name: '厨房'
            },
            {
                url: '/product',
                name: '餐厅'
            },
            {
                url: '/product',
                name: '客厅'
            },
            {
                url: '/product',
                name: '卧室'
            }, {
                url: '/product',
                name: '书房'
            }, {
                url: '/product',
                name: '阳台'
            }, {
                url: '/product',
                name: '卫浴'
            }]
            this.menuList[2].childList = list;
            // getRecommend().then((res) => {
            //     if (res.code + '' === '200') {
            //         let list = res.data;
            //         list.filter(item => item.url = `/productDetail?did=${item.id}`)
            //         this.menuList[2].childList = list;
            //     }
            // });
        },
        goWin() {
            let url = 'https://beian.miit.gov.cn/'
            window.open(url, '_blank');
        },
        goLink(url){
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    height: 605px;
    padding-top: 60px;
    background: #333333;
    color: #fff;
    font-size: 14px;

    .logo-menu {
        display: flex;
        justify-content: space-between;

        .left {
            width: 324px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .logo {
                cursor: pointer;

                img {
                    width: 148px;

                }
            }

            .phone {
                .item {
                    margin-top: 12px;

                    .text {
                        line-height: 35px;
                    }

                    .phoneNumber {
                        color: #b02930;
                        font-weight: 700;
                        font-size: 30px;
                    }
                }
            }
        }

        .right {
            flex: 1;

            .code-list {
                display: flex;
                align-items: center;

                .item {
                    position: relative;
                    margin-right: 39px;
                    cursor: pointer;

                    .itemCode {
                        display: none;
                        transition: all .5s;
                        position: absolute;
                        bottom: 72px;
                        left: 50%;
                        z-index: 2;
                        transform: translateX(-50%);
                        background: #fff;
                        padding: 2px;
                        border-radius: 2px;

                        img {
                            width: 143px;
                            height: 143px;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translate(-50%, 100%);
                            width: 0;
                            height: 0;
                            border-width: 20px 15px 20px 15px;
                            border-style: solid;
                            border-color: #fff transparent transparent transparent;
                        }
                    }

                    &:hover {
                        .itemCode {
                            display: block;
                        }
                    }
                }
            }

            .menu {
                display: flex;
                margin-top: 54px;

                dl {
                    width: 132px;
                    text-align: center;
                    cursor: pointer;
                }

                dd {
                    margin: 8px 0;
                    color: #acacac;
                    cursor: pointer;
                    cursor: pointer;
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background: #454545;
                    margin: 28px 0;
                }
            }
        }
    }

    .link {
        display: flex;
        align-items: center;
        margin-top: 88px;
        color: #acacac;

        ul {
            display: flex;
            align-items: center;

            li {
                padding: 0 10px;
                cursor: pointer;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 15px;
                    width: 2px;
                    background: #acacac;
                }

                &:hover {
                    color: #b02930;
                }
            }
        }
    }

    .lineBottom {
        width: 100%;
        height: 1px;
        background: #454545;
        margin-top: 2px;
        margin-bottom: 8px;
    }

    .website {
        color: #acacac;

        .beian {
            cursor: pointer;

            &:hover {
                color: #b02930;
            }
        }
    }
}
</style>
