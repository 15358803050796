import request from '@/utils/request';

/* 顶部通用轮播图 */
export function getBanner(params) {
    return request({
        url: `/api/banner?type=${params.type}`, //轮播图类型 1首页轮播 2关于我们 3招商加盟 4产品中心 5服务支持 6新闻资讯 7联系我们 8预约量尺
        method: 'get',
        data: params
    })
}
// 推荐产品
export function getRecommend(params) {
    return request({
        url: `/api/goods/recommend`,
        method: 'get',
        data: params
    })
}

/* 公司动态 */
export function getCompanyNew(params) {
    return request({
        url: `/api/dynamic`,
        method: 'get',
        data: params
    })
}

/* 职位列表 */
export function getPost(params) {
    return request({
        url: `/api/job/getOffice`,
        method: 'get',
        data: params
    })
}
/* 招聘列表 */
export function getOffice(params) {
    return request({
        url: `/api/job/office?pageNum=${params.pageNum}&pageSize=${params.pageSize}&officeId=${params.officeId}&name=${params.name}`,
        method: 'get',
        data: params
    })
}
/* 上传文件 (暂无用)*/
export function addFile(params) {
    return request({
        url: `/api/common/upload`,
        method: 'post',
        data: params
    })
}
/* 职位申请 */
export function addOffice(params) {
    return request({
        url: `/api/apply`,
        method: 'post',
        data: params
    })
}


/* 省市区 */
export function getProvince(params) {
    return request({
        url: `/api/district/province`,
        method: 'get',
        data: params
    })
}

/* 新增免费设计 */
export function addDesign(params) {
    return request({
        url: `/api/design`,
        method: 'post',
        data: params
    })
}



/* 品牌实力背景图 */
export function getbrandStrengthBg(params) {
    return request({
        url: `/api/brand`,
        method: 'get',
        data: params
    })
}

/* 常见问题 */
export function getProblem(params) {
    return request({
        url: `/api/problem?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 预约量尺 */
export function addDesignArea(params) {
    return request({
        url: `/api/appoint`,
        method: 'post',
        data: params
    })
}

/* 品牌视频 */
export function getBrandVideo(params) {
    return request({
        url: `/api/video`,
        method: 'get',
        data: params
    })
}

/* 公益理念 */
export function getAction(params) {
    return request({
        url: `/api/action?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 公益理念相关推荐 */
export function getActionRecommend(params) {
    return request({
        url: `/api/action/recom?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 公益理念详情 */
export function getActionDetail(params) {
    return request({
        url: `/api/action/${params.id}`,
        method: 'get',
        data: params
    })
}


/* 融润生态 */
export function getECO(params) {
    return request({
        url: `/api/eco`,
        method: 'get',
        data: params
    })
}

/* 招商加盟 */
export function addJoin(params) {
    return request({
        url: `/api/message`,
        method: 'post',
        data: params
    })
}

/* 新闻中心 */
export function getNews(params) {
    return request({
        url: `/api/dynamic?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 新闻中心相关推荐 */
export function getNewsRecommend(params) {
    return request({
        url: `/api/dynamic/recom?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 新闻中心详情 */
export function getNewsDetail(params) {
    return request({
        url: `/api/dynamic/${params.id}`,
        method: 'get',
        data: params
    })
}


/* 查询商品分类 */
export function getProductClass(params) {
    return request({
        url: `/api/goods/getSort`,
        method: 'get',
        data: params
    })
}

/* 查询商品 */
export function getProduct(params) {
    return request({
        url: `/api/goods/sort?pageNum=${params.pageNum}&pageSize=${params.pageSize}&spaceId=${params.spaceId}&styleId=${params.styleId}&seriesId=${params.seriesId}&elseId=${params.elseId}`,
        method: 'get',
        data: params
    })
}
/* 商品中心相关推荐 */
export function getProductRecommend(params) {
    return request({
        url: `/api/goods/recommend?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
/* 商品中心详情 */
export function getProductDetail(params) {
    return request({
        url: `/api/goods/${params.id}`,
        method: 'get',
        data: params
    })
}
/* 设计师列表 */
export function getTeam(params) {
    return request({
        url: `/api/team`,
        method: 'get',
        data: params
    })
}


/**以下是新接口对接**/
export function getHomeSlide(params) {
    return request({
        url: `/api/portal/slide/index`,
        method: 'post',
        data: params
    })
}
export function getHomeProduct(params) {
    return request({
        url: `/api/portal/slide/product`,
        method: 'post',
        data: params
    })
}
//产品分类
export function getProductCat(params) {
    return request({
        url: `/api/portal/slide/cat`,
        method: 'get',
        data: params
    })
}
//产品列表
export function getProductList(params) {
    return request({
        url: `/api/portal/slide/products`,
        method: 'post',
        data: params
    })
}
//产品详情
export function getProductDetailById(params) {
    return request({
        url: `/api/portal/slide/detail`,
        method: 'post',
        data: params
    })
}
//省市区三级联动
export function getSlideProvince(params) {
    return request({
        url: `/api/portal/slide/province`,
        method: 'get',
        data: params
    })
}
//预约量尺
export function sendYuyue(params) {
    return request({
        url: `/api/portal/slide/yuyue`,
        method: 'post',
        data: params
    })
}